import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  appBar: {
    alignItems: 'center'
  },
};

const NavBar = (props) => {
  const { classes } = props;
  return(
      <AppBar position="static" className={classes.appBar}>
          <Toolbar>
              <Typography variant="h6" color="inherit" align="center">
              Savings Planner
              </Typography>
          </Toolbar>
      </AppBar>
  );
}

export default withStyles(styles)(NavBar);
