import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

/*

The formula used in the compound interest calculator is A = P(1+r/n)^(nt)
Future value of a series formula is PMT * ( ( (1 + r/n)^(nt) - 1 ) / (r/n) ) * (1+r/n)
Solved for PMT:
PMT = ( F - (P * (1+rate)^nPer)) / ( ((1+rate)^nPer - 1) / rate )

F = Future value
P = the principal investment amount
PMT = periodic contribution

rate = ( ( 1 + r / n )^( n / p ) ) - 1
nPer = p * t

r = the interest rate (decimal)
n = the number of compound periods per year
p = the number of contribution periods per year
t = the time in years

*/

const styles = {
  buttonMargin: {
    margin: {
      top: 15,
    }
  },
};

class GoalCalc extends Component {

  constructor(props) {
		super(props);

    this.state = {
			goal: "1,000,000",
      principal: "1,000",
      rate: 5,
      compound: 12,
      time: "25",
      contributionAmt: 0,
      error: false,
    };
  }

  genericOnChange = (e) => {
    let { name, value, dataset } = e.target;
    if(dataset && dataset.convertNumber === "true") {
        value = parseFloat(value.replace(/,/g, '')).toLocaleString(navigator.language, { maximumFractionDigits: 2 });
        if(Number.isNaN(value) || value === '') {
          value = '0';
        }
    }
		this.setState({ [name]: value });
  }

  calculate = (e) => {
    e.preventDefault();
    let { goal, principal, rate, compound, time } = this.state;

    // reset error message
    this.setState({error: false});

    goal = parseFloat(goal.replace(/,/g, ''));
    principal = parseFloat(principal.replace(/,/g, ''));
    let r = rate / 100;
    let n = compound;
    let p = 12; // assuming a constant of monthly payments
    let t = parseFloat(time.replace(/,/g, ''));

    let cRate = ( Math.pow(( 1 + r / n ), ( n / p )) ) - 1;
    let nPer = p * t;

    // PMT = ( F - (P * (1+rate)^nPer)) / ( ((1+rate)^nPer - 1) / rate )
    let result = (goal - (principal * Math.pow(1+cRate, nPer)) ) / ( ( Math.pow(1 + cRate, nPer) - 1 ) / cRate );

    this.setState({
      contributionAmt: result
    });

  }

  render() {
    const { principal, rate, compound, time, goal, contributionAmt } = this.state;
    const { classes } = this.props;

    let showContrib = false;
    //let totalDisplay = '$' + total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let contribDisplay = '$' + contributionAmt.toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    if(contributionAmt > 0) {
      showContrib = true;
    }

    return (
      <div className="calculator">
        <Box mb="25px">
          <Typography variant="body1">Use this calculator to determine how much you must save monthly to meet your goal.</Typography>
        </Box>
        <form id="calculator" onSubmit={this.calculate}>
					<TextField
            name="goal"
            variant="outlined"
            required
            fullWidth
            id="goal"
            label="Savings Goal"
            value={goal}
            onChange={this.genericOnChange}
            margin="normal"
            inputProps={{ 'data-convert-number': true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            autoFocus
          />
					<TextField
            name="principal"
            variant="outlined"
            required
            fullWidth
            id="principal"
            label="Starting Balance"
            value={principal}
            onChange={this.genericOnChange}
            margin="normal"
            inputProps={{ 'data-convert-number': true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            name="rate"
            variant="outlined"
            required
            fullWidth
            id="rate"
            label="Interest Rate"
            value={rate}
            onChange={this.genericOnChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            helperText="Annual Rate"
            margin="normal"
          />
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            required
          >
            <InputLabel htmlFor="compound">Compound Frequency</InputLabel>
            <Select
              id="compound"
              name="compound"
              value={compound}
              onChange={this.genericOnChange}
              labelWidth={170}
            >
              <MenuItem value={12}>Monthly</MenuItem>
              <MenuItem value={1}>Yearly</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="time"
            variant="outlined"
            required
            fullWidth
            id="time"
            label="Length of Time"
            value={time}
            onChange={this.genericOnChange}
            inputProps={{ 'data-convert-number': true }}
            InputProps={{
              endAdornment: <InputAdornment position="start">years</InputAdornment>,
            }}
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonMargin}
          >
            Calculate
          </Button>
        </form>
        <Box className="calc-outputs" mt="15px">
          {showContrib &&
            <Typography variant="h6" gutterBottom>
              Need to Contribute: {contribDisplay} monthly
            </Typography>
          }
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(GoalCalc);
