import React from 'react';
import Container from '@material-ui/core/Container';
import NavBar from './components/NavBar';
import CompoundInterestCalc from './components/CompoundInterestCalc';
import GoalCalc from './components/GoalCalc';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { create } from 'jss';
import jssexpand from 'jss-plugin-expand';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#05386B",
    },
    secondary: {
      main: "#379683",
    }
  },
});

const jss = create({
  plugins: [...jssPreset().plugins, jssexpand()]
});

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar
}));

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <NavBar />
        <div className={classes.appBarSpacer} />
        <Container maxWidth="sm">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            centered
          >
            <Tab label="How do I meet my goal?" {...tabProps(0)} />
            <Tab label="How much will I have?" {...tabProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <GoalCalc />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CompoundInterestCalc />
          </TabPanel>


        </Container>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
